import React, { useState, useEffect, useRef } from "react";
import StarRating from "../components/starRating";
import Dropdown from "../components/dropdown";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function EditReview({ reviewID, gameID }) {
	const [backgroundImg, setBackgroundImg] = useState("");
	const [gameImg, setGameImg] = useState("");
	const [dbRating, setDbRating] = useState(null);
	const reviewRef = useRef("");
	const [platform, setPlatform] = useState("");
	const navigate = useNavigate();
	const reviews = JSON.parse(sessionStorage.getItem("reviews"));
	let review = reviews.find((review) => {
		return review.id === reviewID;
	});
	console.log(reviewID);
	console.log(reviews);
	console.log(review);

	function getRating(rating) {
		setDbRating(rating);
	}

	function getPlatform(platform) {
		setPlatform(platform);
	}

	useEffect(() => {
		setDbRating(review.rating);
		setPlatform(review.platform);
		reviewRef.current.value = review.reviewText;
		setBackgroundImg(review.backgroundImg);
		setGameImg(review.gameImg);
	}, [review]);

	function updateReview() {
		if (!reviewRef.current.value || !dbRating || !platform) {
			alert("please complete the review");
		} else {
			console.log("reviews before", reviews);
			review.reviewText = reviewRef.current.value;
			review.rating = dbRating;
			review.platform = platform;
			reviews.forEach((item, index) => {
				if (item.id === review.id) {
					reviews.splice(index, 1, review);
				}
			});
			sessionStorage.setItem("reviews", JSON.stringify(reviews));
			console.log("reviews after", reviews);
			toast.success("review updated!", { style: { background: "#212529", color: "white", border: "1px solid gray" } });
			navigate("/reviews");
		}
	}

	return (
		<div className="create-review vh-100 bg-dark container-fluid p-0">
			<div className="background position-relative">
				<div className="gradient position-absolute"></div>
				<img className="background-img w-100 h-100" src={backgroundImg ? require(`../assets/images/${backgroundImg}`) : null} alt="" />
				<div className="background-upload position-absolute top-0 start-0 "></div>
				<div className="game-img position-absolute start-50 top-50 translate-middle text-center">
					<img className="img-fluid h-100 w-100" src={gameImg ? require(`../assets/images/${gameImg}`) : null} alt="" />
				</div>
			</div>
			<div className="container">
				<div className="row justify-content-center mt-2 ">
					<div className="col-12 justify-content-center w-75">
						<textarea
							className="d-block w-100 bg-transparent text-white"
							cols="40"
							rows="7"
							ref={reviewRef}
							placeholder="Your Review Here..."></textarea>
						<button
							className="btn btn-primary d-block mx-auto mt-2"
							type="button"
							onClick={() => {
								updateReview();
							}}>
							Update review
						</button>
					</div>
					<div className="col-12 justify-content-between d-flex mt-3">
						<StarRating getRating={getRating} dbRating={dbRating} />
						<Dropdown getPlatform={getPlatform} platform={platform} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditReview;
