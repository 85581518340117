import React, { useState, useRef } from "react";
import StarRating from "../components/starRating";
import Dropdown from "../components/dropdown";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function CreateReview({ gameImg1, background1, gameID, gameTitle }) {
	const [rating, setRating] = useState(null);
	const [platform, setPlatform] = useState("");
	const navigate = useNavigate();
	const reviewTextRef = useRef("");
	const user = useRef(sessionStorage.getItem("user"));
	const userId = useRef(sessionStorage.getItem("userId"));
	const review = {
		id: "",
		userId: "",
		user: "",
		gameId: gameID,
		gameTitle: gameTitle,
		gameImg: gameImg1,
		backgroundImg: background1,
		reviewText: "",
		rating: "",
		platform: "",
	};
	let reviews = [];
	if (sessionStorage.getItem("reviews")) {
		reviews = JSON.parse(sessionStorage.getItem("reviews"));
		console.log(reviews);
	}

	function getRating(rating) {
		setRating(rating);
	}

	function getPlatform(platform) {
		setPlatform(platform);
	}

	function createReview() {
		if (!reviewTextRef.current.value || !rating || !platform) {
			toast.error("please complete the review", {
				style: {
					background: "#212529",
					color: "white",
					border: "1px solid gray",
				},
			});
		} else {
			review.id = reviews.length + 1;
			review.userId = userId.current;
			review.user = user.current;
			review.reviewText = reviewTextRef.current.value;
			review.rating = parseInt(rating);
			review.platform = platform;
			console.log(review);
			reviews.push(review);
			sessionStorage.setItem("reviews", JSON.stringify(reviews));
			console.log(reviews);
			toast.success("review created!", {
				style: {
					background: "#212529",
					color: "white",
					border: "1px solid gray",
				},
			});
			navigate("/");
		}
	}

	return (
		<div className="create-review vh-100 bg-dark container-fluid p-0">
			<div className="background position-relative">
				<div className="gradient position-absolute"></div>
				<img className="background-img w-100 h-100" src={require(`../assets/images/${background1}`)} alt="" />
				<div className="background-upload position-absolute top-0 start-0 "></div>
				<div className="game-img position-absolute start-50 top-50 translate-middle text-center">
					<img className="img-fluid h-100 w-100" src={require(`../assets/images/${gameImg1}`)} alt="" />
				</div>
			</div>
			<div className="container">
				<div className="row justify-content-center mt-2 ">
					<div className="col-12 justify-content-center w-75">
						<textarea
							className="d-block w-100 bg-transparent text-white"
							cols="40"
							rows="7"
							placeholder="Your Review Here..."
							ref={reviewTextRef}></textarea>
						<button
							className="btn btn-primary d-block mx-auto mt-2"
							type="button"
							onClick={() => {
								createReview();
							}}>
							create review
						</button>
					</div>
					<div className="col-12 justify-content-between d-flex mt-3">
						<StarRating getRating={getRating} />
						<Dropdown getPlatform={getPlatform} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreateReview;
