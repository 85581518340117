import React, { useState, useEffect, useRef } from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function Reviews({ getGameID, getID }) {
	const reviews = useRef([]);
	const [isExpanded, setIsExpanded] = useState([]);
	const reviewRef = useRef([]);
	const userIdRef = useRef(sessionStorage.getItem("userId"));
	const [showReadMoreOrLess, setShowReadMoreOrLess] = useState([]);
	const [userReviews, setUserReviews] = useState([]);

	useEffect(() => {
		if (sessionStorage.getItem("reviews")) {
			reviews.current = JSON.parse(sessionStorage.getItem("reviews"));
			let latestUserReviews = reviews.current.filter((item) => {
				return item.userId === userIdRef.current;
			});
			setUserReviews(latestUserReviews.reverse());
		}
	}, []);

	useEffect(() => {
		setShowReadMoreOrLess(
			userReviews.map(() => {
				return false;
			})
		);
	}, [userReviews]);

	useEffect(() => {
		if (reviewRef.current[0]) {
			reviewRef.current.forEach((div, i) => {
				if (div?.scrollHeight > 56) {
					setShowReadMoreOrLess((prevState) => {
						let newArray = [...prevState];
						newArray[i] = true;
						return newArray;
					});
				}
			});
		}
	}, []);

	function expandOrShrink2(index, e) {
		if (isExpanded[index] === true) {
			reviewRef.current[index].classList.add("user-review");
			let isExpandedCopy = isExpanded;
			isExpandedCopy[index] = false;
			setIsExpanded(isExpandedCopy);
			e.target.innerHTML = "Read More";
		} else {
			reviewRef.current[index].classList.remove("user-review");
			let isExpandedCopy = isExpanded;
			isExpandedCopy[index] = true;
			setIsExpanded(isExpandedCopy);
			e.target.innerHTML = "Read Less";
		}
	}

	function deleteReview(reviewID) {
		if (window.confirm("Delete Review?")) {
			reviews.current.map((review, index) => {
				if (review.id === reviewID) {
					reviews.current.splice(index, 1);
					sessionStorage.setItem("reviews", JSON.stringify(reviews.current));
					let latestUserReviews = reviews.current.filter((item) => {
						return item.userId === userIdRef.current;
					});
					setUserReviews(latestUserReviews.reverse());
				}
				return userReviews;
			});
			toast.success("review deleted", { style: { background: "#212529", color: "white", border: "1px solid gray" } });
		}
	}

	return (
		<div className="review-card container mb-4 ">
			<h1 className="mb-3 text-white text-center mt-2">My Reviews</h1>
			{userReviews?.length ? (
				userReviews.map((review, index) => {
					return (
						<div className="review p-2 mb-2 position-relative rounded" key={review.id}>
							<div className="review-icons row w-auto m-auto  position-absolute top-0 end-0">
								<div className="col px-1 py-2">
									<Link to="/editreview">
										<img
											className="edit-icon bg-secondary rounded-2 px-1"
											src={require("../assets/images/edit-icon3.png")}
											alt="edit"
											onClick={() => getGameID(review.gameId, getID(review.id))}
										/>
									</Link>
								</div>
								<div className="col px-1 py-2">
									<img
										className="delete-icon bg-secondary rounded-2 px-1"
										src={require("../assets/images/trashcan2.png")}
										alt="delete"
										onClick={() => deleteReview(review.id)}
									/>
								</div>
							</div>
							<div className="row w-100 m-auto">
								<h2 className="text-white">{review.gameTitle}</h2>
							</div>
							<div className="row w-100 m-auto">
								<div className="col-2 mh-100 text-center">
									<img
										className="review-img img-fluid"
										src={require(`../assets/images/${review.gameImg}`)}
										alt={review.gameTitle}
									/>
								</div>
								<div className="col text-white p-0 ">
									<div className="user-name row w-100 m-auto">{review.user}</div>
									<div className="platform row mt-2 w-100 m-auto">{review.platform}</div>
									<div className="row mt-2 justify-content-start w-100 m-auto">
										<div className="rating1 col p-0">
											{[...Array(5)].map((star, i) => {
												return <FaStar color={review.rating >= i + 1 ? "#ffc107" : "#e4e5e9"} key={i} />;
											})}
										</div>
									</div>
									<div className={"row w-100 m-auto mt-2 rounded" + index}>
										<div className="user-review bg-secondary rounded p-1" ref={(item) => reviewRef.current.push(item)}>
											{review.reviewText}
										</div>
										{showReadMoreOrLess[index] ? (
											<Link className="text-decoration-none text-center" onClick={(e) => expandOrShrink2(index, e)}>
												Read More
											</Link>
										) : null}
									</div>
									<div className="row w-100 m-auto text-white">
										<div className="col-2">likes</div>
										<div className="col-2">comments</div>
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<h1 className="text-white position-absolute start-50 top-50 translate-middle text-center">You have no reviews</h1>
			)}
		</div>
	);
}

export default Reviews;
