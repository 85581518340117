import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";

function List() {
	const listId = sessionStorage.getItem("listId");
	const [listGames, setListGames] = useState([]);
	const lists = JSON.parse(sessionStorage.getItem("lists"));
	const thisList = useRef(
		lists.find((list) => {
			return list.id === Number(listId);
		})
	);

	console.log(thisList.current);

	useEffect(() => {
		setListGames([...thisList.current.games].reverse());
	}, []);

	function deleteListGame(gameId, game, index) {
		console.log(gameId);
		if (window.confirm(`remove ${game.title} from list?`)) {
			thisList.current.games.forEach((item, index) => {
				if (item.id === gameId) {
					thisList.current.games.splice(index, 1);
					let newGamesArray = [...thisList.current.games];
					console.log(newGamesArray);
					console.log(thisList.current.games);
					setListGames(newGamesArray.reverse());
					for (let list of lists) {
						if (list.id === thisList.current.id) {
							list.games = thisList.current.games;
							sessionStorage.setItem("lists", JSON.stringify(lists));
						}
					}
					toast.success("game removed", { style: { background: "#212529", color: "white", border: "1px solid gray" } });
				}
			});
		}
	}
	return (
		<div className="container mb-3">
			<div className="row text-center mt-2">
				<h1 className="text-white">{thisList.title}</h1>
			</div>

			<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 w-75 m-auto row-gap-1">
				{listGames.map((game, index) => {
					return (
						<div className="gamecol col rounded p-2 position-relative" key={game.id}>
							<div className="d-block h-100">
								<img className="myreview-game rounded w-100 " src={require(`../assets/images/${game.img}`)} alt={game.title} />
								<div className="hover-name position-absolute top-50 start-50 translate-middle text-white fw-bold text-center">
									{game.title}
								</div>
							</div>
							<div className="delete-listGame-icon position-absolute rounded-5">
								<img
									className="bg-secondary rounded-5"
									src={require("../assets/images/trashcan2.png")}
									alt=""
									onClick={() => deleteListGame(game.id, game, index)}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default List;
