import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function LoginForm({ active, setActive, onClickOutside, setUserLogged, setRegisterActive, userName, getUserId }) {
	const visible =
		"col-9 col-sm-7 col-md-5 col-lg-3 p-2 login-form bg-dark position-absolute top-50 start-50 translate-middle rounded border border-light";
	const invisible = "login-form-invisible form-control w-25 bg-dark position-absolute";
	const formRef = useRef(null);
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	let usersData = [];

	if (JSON.parse(sessionStorage.getItem("usersData"))) {
		usersData = JSON.parse(sessionStorage.getItem("usersData"));
	}

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (formRef.current && !formRef.current.contains(e.target)) {
				onClickOutside();
				emailRef.current.value = "";
				passwordRef.current.value = "";
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [onClickOutside]);

	const logIn = () => {
		if (usersData.length) {
			for (let i = 0; i < usersData.length; i++) {
				if (emailRef.current.value === usersData[i].email && passwordRef.current.value === usersData[i].password) {
					sessionStorage.setItem("userId", usersData[i].id);
					userName(usersData[i].name);
					getUserId(usersData[i].id);
					setUserLogged(true);
					setActive(false);
					sessionStorage.setItem("logged", true);
					sessionStorage.setItem("user", usersData[i].name);
					toast.success(`Logged in successfully!\n Hi ${sessionStorage.getItem("user")}!`, {
						style: { background: "#212529", color: "white", border: "1px solid gray" },
					});
					break;
				} else if (i >= usersData.length - 1) {
					toast.error("wrong email or password", { style: { background: "#212529", color: "white", border: "1px solid gray" } });
				}
			}
		} else {
			toast.error("user not registered", { style: { background: "#212529", color: "white", border: "1px solid gray" } });
		}
	};

	function registerLink(e) {
		e.preventDefault();
		setActive(false);
		setRegisterActive(true);
	}

	return (
		<div className="row">
			<form className={active ? visible : invisible} ref={formRef}>
				<div className="row w-100 m-auto mb-2">
					<label className="text-white ps-1" htmlFor="login-email-input">
						Email Address
					</label>
					<input className="form-control" type="text" id="login-email-input" placeholder="Email" ref={emailRef} />
				</div>
				<div className="row w-100 m-auto mb-2">
					<label className="text-white ps-1" htmlFor="login-password-input">
						Password
					</label>
					<input
						className="form-control"
						type="password"
						id="login-password-input"
						placeholder="Enter Password"
						ref={passwordRef}
					/>
				</div>
				<div className="row w-100 m-auto justify-content-center">
					<button type="button" className="btn btn-secondary w-50 bg-primary" onClick={logIn}>
						Log In
					</button>
				</div>
				<p className="text-white text-center m-0 p-1">
					Don't have an account? Register here
					<Link
						className="text-decoration-none ms-1"
						onClick={(e) => {
							registerLink(e);
						}}>
						Register
					</Link>
				</p>
			</form>
		</div>
	);
}

export default LoginForm;
