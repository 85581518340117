import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function Lists() {
	const [lists, setLists] = useState([]);
	const listNameRef = useRef("");
	const formRef = useRef(null);
	const userIdRef = useRef(sessionStorage.getItem("userId"));
	// const listInfo = [];
	let lastThreeListInfo = [];
	const createListBtnRef = useRef();
	let newList = { id: "", userId: userIdRef.current, title: "", games: [] };
	let userLists = useRef([]);

	useEffect(() => {
		if (sessionStorage.getItem("lists")) {
			setLists(JSON.parse(sessionStorage.getItem("lists")));
		}
	}, []);

	userLists.current = lists.filter((list) => {
		return list.userId === userIdRef.current;
	});

	const showForm = () => {
		formRef.current.classList.toggle("d-none");
	};

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (formRef.current && !formRef.current.contains(e.target) && !createListBtnRef.current.contains(e.target)) {
				formRef.current.classList.add("d-none");
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	const createList = () => {
		if (listNameRef.current.value) {
			newList.title = listNameRef.current.value;
			newList.id = lists.length + 1;
			let createdList = [...lists, newList];
			sessionStorage.setItem("lists", JSON.stringify(createdList));
			setLists(createdList);
			console.log(lists);
			formRef.current.classList.add("d-none");
			listNameRef.current.value = "";
		} else {
			alert("please enter your list name");
		}
	};

	function lastThreeGames(list) {
		// let filteredListInfo = [];
		lastThreeListInfo = [];
		for (let i = 1; i <= list.games.length; i++) {
			if (i <= 3) {
				lastThreeListInfo.push(list.games[list.games.length - i]);
			} else {
				break;
			}
		}
		console.log(lastThreeListInfo);
	}

	function deleteList(listId, title) {
		console.log(listId);
		if (window.confirm(`Delete ${title} list?`)) {
			let getLists = JSON.parse(sessionStorage.getItem("lists"));
			getLists.forEach((list, index) => {
				if (list.id === listId) {
					getLists.splice(index, 1);
					sessionStorage.setItem("lists", JSON.stringify(getLists));
					setLists(getLists);
					toast.success("list deleted", { style: { background: "#212529", color: "white", border: "1px solid gray" } });
				}
			});
		}
	}

	return (
		<div className="container min-vh-100 position-relative">
			<h1 className="text-center text-white mt-2">My Lists</h1>
			<div className="row justify-content-center mt-3 w-100 m-auto">
				<button className="btn btn-primary w-auto" onClick={showForm} ref={createListBtnRef}>
					Create List
				</button>
			</div>
			<div className="lists row text-center text-white mt-2 w-100 m-auto">
				{userLists.current.map((list) => {
					return (
						<div className="list-col col-12 col-md-6 col-lg-4 mt-3 position-relative" key={list.id}>
							<h5 className="row m-auto ms-2 mb-1 text-white">{list.title}</h5>
							<div className="row m-auto">
								{lastThreeGames(list)}
								<div className={`list-game col-5 rounded position-relative `}>
									<Link to="/list" onClick={() => sessionStorage.setItem("listId", list.id)}>
										<div className="w-100 h-100 rounded position-absolute list-img1 list-img border border-secondary">
											{lastThreeListInfo.length && lastThreeListInfo[0] ? (
												<img
													className="h-100 w-100 rounded"
													src={require(`../assets/images/${lastThreeListInfo[0].img}`)}
													alt={lastThreeListInfo[0].title}
												/>
											) : null}
										</div>
										<div className="w-100 h-100 rounded position-absolute list-img2 list-img border border-secondary">
											{lastThreeListInfo.length && lastThreeListInfo[1] ? (
												<img
													className="h-100 w-100 rounded"
													src={require(`../assets/images/${lastThreeListInfo[1].img}`)}
													alt={lastThreeListInfo[1].title}
												/>
											) : null}
										</div>
										<div className="w-100 h-100 rounded position-absolute list-img3 list-img border border-secondary">
											{lastThreeListInfo.length && lastThreeListInfo[2] ? (
												<img
													className="h-100 w-100 rounded"
													src={require(`../assets/images/${lastThreeListInfo[2].img}`)}
													alt={lastThreeListInfo[2].title}
												/>
											) : null}
										</div>
									</Link>
								</div>
							</div>
							<div className="delete-list-icon position-absolute rounded-3">
								<img
									className="bg-secondary rounded-3"
									src={require("../assets/images/trashcan2.png")}
									alt="delete"
									onClick={() => deleteList(list.id, list.title)}
								/>
							</div>
						</div>
					);
				})}
			</div>
			<div
				className="form row position-absolute start-50 top-50 translate-middle d-none m-auto justify-content-center bg-secondary rounded"
				ref={formRef}>
				<div className="col-12 p-1">
					<div className="row w-100 m-auto text-center">
						<label className="text-white m-0 p-0  m-auto" htmlFor="listName">
							List Name
						</label>
						<input type="text" name="listName" ref={listNameRef} />
						<button className="btn btn-primary mt-2 w-auto m-auto" onClick={createList}>
							Create
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Lists;
