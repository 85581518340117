import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

function ReviewCard() {
	let reviews = [];
	const [isExpanded, setIsExpanded] = useState([]);
	const reviewRef = useRef([]);
	const [showReadMoreOrLess, setShowReadMoreOrLess] = useState([]);
	let reverseReviews;
	let recentReviews = [];

	if (sessionStorage.getItem("reviews")) {
		reviews = JSON.parse(sessionStorage.getItem("reviews"));
		reverseReviews = [...reviews].reverse();
		for (let i = 0; i < reverseReviews.length; i++) {
			if (i <= 3) {
				recentReviews.push(reverseReviews[i]);
			} else {
				break;
			}
		}
		console.log(reviews);
		console.log(recentReviews);
		console.log("reverse", reverseReviews);
	}

	function expandOrShrink2(index, e) {
		if (isExpanded[index] === true) {
			reviewRef.current[index].classList.add("user-review");
			let isExpandedCopy = isExpanded;
			isExpandedCopy[index] = false;
			setIsExpanded(isExpandedCopy);
			e.target.innerHTML = "Read More";
		} else {
			reviewRef.current[index].classList.remove("user-review");
			let isExpandedCopy = isExpanded;
			isExpandedCopy[index] = true;
			setIsExpanded(isExpandedCopy);
			e.target.innerHTML = "Read Less";
		}
	}

	useEffect(() => {
		if (reviewRef.current[0]) {
			reviewRef.current.forEach((div, i) => {
				if (div?.scrollHeight > 56) {
					setShowReadMoreOrLess((prevState) => {
						let newArray = [...prevState];
						newArray[i] = true;
						return newArray;
					});
				}
			});
		}
	}, []);

	return (
		<div className="review-card container">
			{recentReviews.length ? (
				recentReviews.map((review, index) => {
					return (
						<div className="p-1 mb-2 rounded border border-secondary" key={review.id}>
							<div className="row w-100 m-auto">
								<h2 className="text-white">{review.gameTitle}</h2>
							</div>
							<div className="row w-100 m-auto">
								<div className="col-2 mh-100 text-center p-2">
									<img
										className="review-img img-fluid"
										src={require(`../../assets/images/${review.gameImg}`)}
										alt={review.gameTitle}
									/>
								</div>
								<div className="col text-white p-0 ">
									<div className="user-name row w-100 m-auto">{review.user}</div>
									<div className="platform row mt-2 w-100 m-auto">{review.platform}</div>
									<div className="row mt-2 justify-content-start w-100 m-auto">
										<div className="rating1 col p-0">
											{[...Array(5)].map((star, i) => {
												return <FaStar color={review.rating >= i + 1 ? "#ffc107" : "#e4e5e9"} key={i} />;
											})}
										</div>
									</div>
									<div className={"row w-100 m-auto mt-2 rounded" + index}>
										<div className="user-review bg-secondary rounded p-1" ref={(item) => reviewRef.current.push(item)}>
											{review.reviewText}
										</div>
										{showReadMoreOrLess[index] ? (
											<Link className="text-decoration-none text-center" onClick={(e) => expandOrShrink2(index, e)}>
												Read More
											</Link>
										) : null}
									</div>
									<div className="row w-100 m-auto text-white">
										<div className="col-2">likes</div>
										<div className="col-2">comments</div>
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<h1 className="text-white text-center">No reviews yet</h1>
			)}
		</div>
	);
}

export default ReviewCard;
